




































import Component from 'vue-class-component'
import {Prop} from 'vue-property-decorator';
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniRatingBar from "@/components/SygniRatingBar.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {CRMData} from "@/modules/genprox/modules/profile/models/crm-data.interface";
import {mapGetters} from "vuex";
import {InvestorSelects} from "@/modules/shared/dictionary-store/types";
import CapitalRisePermissions from '../../../fund/modules/fundraising/components/CapitalRisePermissions.vue';
import GenproxButton from '@/components/buttons/GenproxButton.vue';

Component.registerHooks(['validations'])
@Component({
  components: {SygniRoundedButton, SygniRatingBar, SygniContainerTitle, SygniCard, GenproxButton},
  computed: {
    ...mapGetters('dictionary', {
      investorSelects: 'investorsSelects',
    })
  },
})

export default class ProfileCrmData extends CapitalRisePermissions {
  @Prop() crmData!: CRMData;
  @Prop() investorId!: string;
  @Prop() viewType!: 'owner' | 'investor' | 'guest';
  investorSelects!: InvestorSelects;

  get displayEditBtn() {
    if(this.viewType === 'investor' && !this.manageInvestorsEnabled) {
      return false;
    }

    return true;
  }

  get dictionaries() {
    return this.$store.getters['profile/getDictionaries'];
  }

  get industries() {
    if(this.dictionaries === undefined) return [];

    return this.dictionaries?.industry?.filter((industry: any) => {
      return this.crmData?.industries?.includes(industry.value);
    });
  }

  get isCRMDataVisible(): boolean {
    const crmData = this.crmData;
    return Boolean(crmData?.focusArea || crmData?.industries?.length > 0 || crmData?.investmentPotential ||
        crmData?.investmentPotential || crmData?.notes)
  }

  get maxInvestmentPotential(): number {
    return this.investorSelects.investmentPotentials.length;
  }

  get maxInvestmentKnowledge(): number {
    return this.investorSelects.investmentKnowledge.length;
  }

  get investmentPotentialAsNumber(): number {
    let index = -1;
    this.investorSelects?.investmentPotentials.find( (potential, potentialIndex) => {
          if( potential.label === this.crmData.investmentPotential ||
              potential.value === this.crmData.investmentPotential ){
            index = potentialIndex + 1;
          }
    })
    return index;
  }

  get InvestmentKnowledgeAsNumber(): number {
    let index = -1;
    this.investorSelects?.investmentKnowledge.find( (potential, potentialIndex) => {
      if( potential.label === this.crmData.investmentKnowledge ||
          potential.value === this.crmData.investmentKnowledge ){
        index = potentialIndex + 1;
      }
    })
    return index;
  }

  editProfile(): void {
    if(!this.displayEditBtn || this.viewType === 'guest') return;
    if(this.viewType === 'owner'){
      this.$router.push({name: 'profile-edit-general'});
    } else if (this.viewType === 'investor'){
      this.$router.push({name: 'investors-preview-investor', params: {id: this.investorId}})
    }
  }

  async beforeMount() {
    if(this.dictionaries === undefined) {
      await this.$store.dispatch('profile/getDictionaries');
    }
  }
}
