var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile-dashboard-container"
  }, [_c('div', {
    staticClass: "profile-dashboard-container__main"
  }, [_c('GenproxPageHeading', {
    attrs: {
      "title": "Investor profile"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('GenproxButton', {
          attrs: {
            "outline": true,
            "icon": _vm.ChevronLeftIcon
          },
          on: {
            "click": function click($event) {
              return _vm.$router.go(-1);
            }
          }
        }, [_vm._v("Go back")])];
      },
      proxy: true
    }])
  }), _c('profile-presentation', {
    attrs: {
      "data": _vm.profile
    }
  }), _vm.viewType === 'owner' && _vm.activeUserContext.context === _vm.contexts.privateInvestor || _vm.viewType === 'investor' ? _c('profile-investor-main-content') : _vm._e(), _c('div', {
    staticClass: "about"
  }, [_c('sygni-container-title', [_vm._v(" About ")]), _c('div', {
    class: {
      'about__text': true,
      'about__text--open': _vm.showMoreAbout
    }
  }, [_vm._v(" " + _vm._s(_vm.profile.generalData.professionalDescription) + " ")]), !_vm.showMoreAbout ? _c('sygni-rounded-button', {
    staticClass: "outline gn-secondary",
    on: {
      "click": function click($event) {
        _vm.showMoreAbout = true;
      }
    }
  }, [_vm._v(" Show more...")]) : _vm._e()], 1)], 1), _c('profile-side-bar', {
    staticClass: "profile-dashboard-container__sidebar",
    attrs: {
      "profile": _vm.profile,
      "businessLinks": _vm.businessLinks,
      "viewType": _vm.viewType,
      "activeUserContext": _vm.activeUserContext,
      "investorId": _vm.investorId
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }